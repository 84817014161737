.border-radius-32 {
  border-radius: 32px;
}

.box-shadow {
  box-shadow: 0px 8px 16px rgba(0,0,0,0.25);
}

.padding-1 {
  padding: 1em;
}

.bold {
  font-weight: bold;
}

.normal {
  font-weight: normal;
}


/*colors*/

.dark-grey { color: #4B4A4B; }

button:focus {
  outline: 0;
}
