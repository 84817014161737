.signup {
  background: #1a2038;

  .signup-card {
    max-width: 800px;
    min-width: 768px;
    border-radius: 12px !important;
    img {
      width: 200px;
    }
  }
}
